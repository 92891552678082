<div class="filters d-flex justify-content-between align-items-center">
  <form class="search-table">
    <input
      type="text"
      name="search"
      placeholder="Search ...."
      (input)="onGlobalSearch($event)"
    />
    <iconify-icon icon="ion:search-outline" class="icon"></iconify-icon>
  </form>
  <div *ngFor="let filter of dropdownFilters" class="dropdown-filter">
    <!-- <label>{{ filter.label }}</label> -->
    <select [(ngModel)]="filter.selected">
      <option *ngFor="let option of filter.options" [value]="option">
        {{ option }}
      </option>
    </select>
    <iconify-icon icon="ic:round-arrow-drop-down"></iconify-icon>
  </div>

  <button class="btn-export" (click)="onExportClick()">
    <iconify-icon icon="ph:file-csv-bold"></iconify-icon>

    {{ exportButtonText }}
  </button>
  <button class="btn-action" (click)="onActionClick()">
    <iconify-icon icon="ic:outline-add"></iconify-icon>
    {{ actionButtonText }}
  </button>
</div>

<ng-container *ngIf="customComponent">
  <ng-template [ngTemplateOutlet]="customComponent"></ng-template>
</ng-container>

<table class="table table-striped table-bordered">
  <thead>
    <tr>
      <th *ngFor="let col of visibleColumns" class="th_table">
        {{ col.header !== "Action" ? col.header : "" }}

        <button
          *ngIf="col.header === 'Action'"
          mat-icon-button
          (click)="openColumnSelector()"
        >
          <iconify-icon icon="ant-design:setting-filled"></iconify-icon>
        </button>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="
        let item of filteredData
          | paginate : { itemsPerPage: itemsPerPage, currentPage: currentPage }
      "
      (click)="onRowClick($event, item)"
    >
      <td *ngFor="let col of visibleColumns">
        <!-- Other column data -->
        <ng-container *ngIf="col.field !== 'action'">
          {{ item[col.field] }}
        </ng-container>

        <!-- Actions column -->
        <ng-container *ngIf="col.field === 'action'">
          <div class="dropdown">
            <ng-container *ngIf="poupdata">
              <ng-template
                [ngTemplateOutlet]="poupdata"
                [ngTemplateOutletContext]="{ $implicit: item }"
              ></ng-template>
            </ng-container>
          </div>
        </ng-container>
      </td>
    </tr>
  </tbody>
</table>

<div class="pagination-ms">
  <mat-paginator
    *ngIf="totalItems !== 0"
    [length]="totalItems"
    [pageSize]="itemsPerPage"
    [pageSizeOptions]="[1, 2, 5, 10, 20]"
    aria-label="{{ 'itemsParPage' }}"
    [showFirstLastButtons]="true"
    (page)="onNextItem($event)"
  ></mat-paginator>
</div>
