import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import 'bootstrap-daterangepicker';
import $ from 'jquery';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import 'moment/locale/fr'; // Importer la locale française
import { ITEMS_PER_PAGE } from 'src/app/core/config/pagination.constants';
import { setPagination } from 'src/app/core/request/setPagination';
import { ClientDataStorageService } from 'src/app/shared/clientDataStorage/client-data-storage.service';
import { FormatMediumDatetimePipe } from 'src/app/shared/date/format-medium-datetime.pipe';
import { ExportService } from 'src/app/shared/utils/export.service';
import { formatDateToYYYYMMDD } from 'src/app/shared/utils/utils.service';
import { ColumnType } from '../../../core/specification/columnType';
import { LogicalOperation } from '../../../core/specification/logicalOperation';
import { Operation } from '../../../core/specification/operation';
import { QuerySearch } from '../../../core/specification/querySearch';
import { RequestSearchService } from '../../../core/specification/request-search.service';
import { RequestSearch } from '../../../core/specification/requestSearch';
import { LayoutService } from '../../../layout/layout.service';
import { NotificationService } from '../../../shared/notification/notification.service';
import { FunctionFiltters } from '../../../shared/utils/status-shipment-filter';
import { IAgencyVM } from '../../agency/models/agency-vm.model';
import { ICityVM } from '../../city/models/city-vm.model';
import { CitiesService } from '../../city/services/cities.service';
import { ICodeTrackingVM } from '../../code-tracking/models/code-tracking-vm.model';
import { CodeTrackingService } from '../../code-tracking/services/code-trakcing.service';
import { ITypeCollectVM } from '../../type-collect/models/type-collect-vm.model';
import { TypeCollectService } from '../../type-collect/services/type-collect.service';
import { IndicatorShipmentVM } from '../indicator-card/indicator-shipment-card.model';
import { ExportShipment } from '../models/expoer-data-shipment.model';
import { IShipmentVM } from '../models/shipment-vm.model';
import { ShipementService } from '../services/shipement.service';

@Component({
  selector: 'milestone-shipment-list',
  templateUrl: './shipment-list.component.html',
  styleUrls: ['./shipment-list.component.scss'],
  providers: [FormatMediumDatetimePipe], // Provide the pipe here
})
export class ShipmentListComponent implements OnInit, AfterViewInit {
  @ViewChild('TABLE') table!: ElementRef;

  dataSource = new MatTableDataSource<IShipmentVM>([]);
  selection = new SelectionModel<IShipmentVM>(true, []);

  // Create an instance of FunctionFiltters
  functionFiltters = new FunctionFiltters();

  cities: ICityVM[] = [];
  statutShipment: ICodeTrackingVM[] = [];
  typesCollects: ITypeCollectVM[] = [];
  requestSearch?: RequestSearch | null;
  indicators: IndicatorShipmentVM[] = [];

  totalItems = 0;
  pageSize = ITEMS_PER_PAGE;
  page = 0;

  showColumns = false;

  displayedColumns: string[] = [
    'select',
    'Date de création',
    'Référence ordre',
    'Référence expédition',
    'Référence client',
    'Type ramassage',
    'Ville depart',
    'Ville arrivee',
    'Taille colis',
    'Id client',
    'Client',
    'Statut',
    'date de modification',
    'action',
  ];

  allColumns: string[] = this.displayedColumns;
  visibleColumns = this.displayedColumns;
  valuesArray: any[] = [];

  // filters
  idShipement: number | null = null;
  idParcelClient = '';
  idOrder = '';
  idsCityDeparture = [];
  idsCityArrival = [];
  clientName = '';
  clientId?: number | null;
  selectedSatatus = '';
  selectedTypeCollect = '';
  startDate?: string | null;
  endDate?: string | null;
  filtersIndicator: string[] = [];
  sortField = 'createdDate';
  sortOrder = 'desc';
  showFiltre = false;

  agency?: IAgencyVM;
  idAgency: string | undefined = '';

  constructor(
    private shipementService: ShipementService,
    private requestSearchService: RequestSearchService,
    private notification: NotificationService,
    private citiesService: CitiesService,
    private codeTrackingService: CodeTrackingService,
    private typeCollectService: TypeCollectService,
    private layoutService: LayoutService,
    protected formatMediumDatetimePipe: FormatMediumDatetimePipe,
    private clientStorageData: ClientDataStorageService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private exportService: ExportService
  ) {}

  ngOnInit(): void {
    this.getAgncy();
    this.getIdicatorsPickup();
    this.getAllCity();
    this.getAllTypeCollect();
    this.getStatutShipment();

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      console.log(params);

      const tabType: string = params['indicator'] ?? '';
      this.selectedSatatus = params['status'] || '';

      // Ensure 'page' is a valid number, default to 1 if it's missing or invalid
      this.page = Number.isNaN(Number(params['page']))
        ? 1
        : parseInt(params['page'], 10);

      this.page = Math.max(this.page - 1, 0); // Ensure page index starts at 0 and is not negative
      this.pageSize = Number.isNaN(Number(params['size']))
        ? 10
        : parseInt(params['size'], 10);

      if (tabType !== '') {
        this.redirect(tabType);
      }

      this.getShipments();
    });

    $('.mat-mdc-text-field-wrapper').css({
      background: 'white',
      height: '46px',
      width: '100%',
    });

    this.layoutService.onMessagePickup().subscribe(() => {
      setTimeout(() => {
        this.getIdicatorsPickup();
        this.getShipments();
      }, 500);
    });
  }

  ngAfterViewInit(): void {
    moment.locale('fr');
    setTimeout(() => {
      this.initializeDateRangePicker();
    }, 100); // Configurer moment pour utiliser le français
  }

  onFiltersChange(): void {
    this.totalItems = 0;

    this.router.navigate([], {
      relativeTo: this.router.routerState.root, // Keeps the current route
      queryParams: {
        status: this.selectedSatatus,
        page: 1,
        size: this.pageSize,
      }, // Update status in query params
      queryParamsHandling: 'merge', // Keep existing query parameters and merge
    });

    this.getShipments();
    this.selection.clear();

    // // Update the URL with the new tabType
  }

  getShipments(): void {
    this.setQuery();
    const sort = [`${this.sortField},${this.sortOrder}`];
    const pagination = setPagination(this.page, this.pageSize, sort);

    this.shipementService
      .query(pagination, this.idAgency, this.requestSearch)
      .subscribe({
        next: (response) => {
          this.dataSource.data = response.content;
          this.totalItems = response.totalElements;
        },
        error: (err) => this.notification.showMessageError(err.error.message),
      });
  }

  isVisible(column: string): boolean {
    return this.visibleColumns.includes(column);
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  toggleColumns(): void {
    this.showColumns = !this.showColumns;
  }

  onTableDataChange(event: any): void {
    this.page = Number(event.pageIndex);
    this.pageSize = event.pageSize;
    this.getShipments();
    this.table.nativeElement.scrollIntoView();
    this.selection.clear();
  }

  onSort(sort: Sort): void {
    this.sortField = sort.active;
    this.sortOrder = sort.direction;
    this.getShipments();
  }

  ExportToExcel(): void {
    if (this.selection.selected.length === 0) {
      alert('No rows selected');
      return;
    }

    const exportData = this.getExportDataShipment();

    this.exportService.exportDataXlsx(exportData, 'shipments');
  }

  exportToPDF(): void {
    if (this.selection.selected.length === 0) {
      alert('No rows selected');
      return;
    }

    const exportData = this.getExportDataShipment();

    const doc = new jsPDF();

    exportData.forEach((item) => {
      this.valuesArray.push(Object.values(item));
    });

    const columnNames = Object.keys(exportData[0]);

    autoTable(doc, {
      head: [columnNames],
      body: this.valuesArray,
    });

    // Save the PDF
    doc.save('ramassage.pdf');
  }

  redirect(val: string | null): void {
    this.selectedSatatus = '';

    console.log(val);

    switch (val) {
      case 'Ramassages non affectés':
        this.filtersIndicator =
          this.functionFiltters.getPickupInfoNotAssigned();
        this.onFiltersChange();
        break;

      case 'Ramassages en cours':
        this.filtersIndicator = this.functionFiltters.getPickupInfoAssigned();
        this.onFiltersChange();
        break;

      case 'Ramassages terminé':
        this.filtersIndicator = this.functionFiltters.getPickedUp();
        this.onFiltersChange();
        break;

      case 'Ramassages avec incident':
        this.filtersIndicator = this.functionFiltters.getPickupIssue();
        this.onFiltersChange();
        break;

      case 'Colis livré':
        this.filtersIndicator = this.functionFiltters.getDelivered();
        this.onFiltersChange();
        break;

      case 'Livraison avec incident':
        this.filtersIndicator = this.functionFiltters.getDeliveryIssue();
        this.onFiltersChange();
        break;

      case 'Colis annulé':
        this.filtersIndicator = this.functionFiltters.getPickupCanceled();
        this.onFiltersChange();
        break;

      case 'Colis reprogrammée':
        this.filtersIndicator = this.functionFiltters.getPickupRescheduled();
        this.onFiltersChange();
        break;

      case 'shipments_waiting_dispatch':
        this.filtersIndicator = this.functionFiltters.getPickupTerminated();
        this.onFiltersChange();
        break;

      case 'shipments_dispatched':
        this.filtersIndicator = this.functionFiltters.getPointingToMMBag();
        this.onFiltersChange();
        break;

      case 'shipment_missed_lost_at_dep':
        this.filtersIndicator = this.functionFiltters.getPointingIssueAtDEP();
        this.onFiltersChange();
        break;

      case 'shipment_forecast':
        this.filtersIndicator = this.functionFiltters.getShipmentForecast();
        this.onFiltersChange();
        break;

      case 'shipment_missed_lost_at_arv':
        this.filtersIndicator = this.functionFiltters.getPointingIssueAtARV();
        this.onFiltersChange();
        break;

      default:
        this.getShipments();
        break;
    }
  }

  showHideBelowFiltre(): void {
    moment.locale('fr'); // Configurer moment pour utiliser le français
    setTimeout(() => {
      this.initializeDateRangePicker();

      $('.mat-mdc-text-field-wrapper').css({
        background: 'white',
        height: '46px',
      });
    }, 100); //

    this.showFiltre = !this.showFiltre;
  }

  /* ========================
          PRIVATE METHODS
     ========================= */

  private padZero(num: number): string {
    return num < 10 ? `0${num}` : num.toString();
  }

  private getStatutShipment(): void {
    this.codeTrackingService.getAllCodeTrakingsForWeb().subscribe({
      next: (response) => (this.statutShipment = response),
      error: () =>
        this.notification.showMessageError('Error loading sttaus order'),
    });
  }

  private initializeDateRangePicker(): void {
    (<any>$('#daterange')).daterangepicker(
      {
        startDate: moment().startOf('year'),
        endDate: moment(),
        opens: 'right',
        locale: {
          cancelLabel: 'Annuler',
          applyLabel: 'Appliqué',
          customRangeLabel: 'Personnalisé',
          toLabel: '~',
          format: 'DD/MM/YYYY',
        },
        ranges: {
          "Aujourd'hui": [moment(), moment()],
          Hier: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          '7 Derniers Jours': [moment().subtract(6, 'days'), moment()],
          '30 Derniers Jours': [moment().subtract(29, 'days'), moment()],
          'Ce Mois': [moment().startOf('month'), moment().endOf('month')],
          'Mois Dernier': [
            moment().subtract(1, 'month').startOf('month'),
            moment().subtract(1, 'month').endOf('month'),
          ],
          'Cette année': [moment().startOf('year'), moment().endOf('year')],
          "L'année dernière": [
            moment().startOf('year').subtract(1, 'year'),
            moment().endOf('year').subtract(1, 'year'),
          ],
        },
      },
      (start: any, end: any) => {
        this.startDate = start.format('YYYY-MM-DD');
        this.endDate = end.format('YYYY-MM-DD');
        this.onFiltersChange();
      }
    );
  }

  private getAllCity(): void {
    this.citiesService.getAllCities().subscribe({
      next: (response) => {
        this.cities = response;
      },
      error: () => {
        this.notification.showMessageError('Error loading Cities');
      },
    });
  }

  private getAllTypeCollect(): void {
    this.typeCollectService.getAll().subscribe({
      next: (response) => {
        this.typesCollects = response;
      },
      error: () => {
        this.notification.showMessageError('Error loading Cities');
      },
    });
  }

  private setQuery(): void {
    this.requestSearchService.clearQueries();

    this.requestSearch = this.getFilters();

    if (this.startDate && this.endDate) {
      const fromDate = new Date(this.startDate);
      const formatFromDate =
        formatDateToYYYYMMDD(fromDate) + ' 00:00:00';

      const toDate = new Date(this.startDate && this.endDate);
      const formatToDate =
        formatDateToYYYYMMDD(toDate) + ' 23:59:59';

      let query: QuerySearch = {
        column: 'createdDate',
        value: formatFromDate,
        operation: Operation.GREATER_THAN_OR_EQUALS,
        columnType: ColumnType.DATE,
        joinTable: null,
        operationJoin: null,
        enumClassName: null,
      };

      this.requestSearch = this.getFilters(query);
      query = {
        column: 'createdDate',
        value: formatToDate,
        operation: Operation.LESS_THAN_OR_EQUALS,
        columnType: ColumnType.DATE,
        joinTable: null,
        operationJoin: null,
        enumClassName: null,
      };
      this.requestSearch = this.getFilters(query);
    }

    if (this.idShipement !== null) {
      const query: QuerySearch = {
        column: 'idShipment',
        value: this.idShipement,
        operation: Operation.EQUALS,
        columnType: ColumnType.LONG,
        joinTable: null,
        operationJoin: null,
        enumClassName: null,
      };

      this.requestSearch = this.getFilters(query);
    }

    if (this.idParcelClient !== '') {
      const query: QuerySearch = {
        column: 'idParcelClient',
        value: this.idParcelClient,
        operation: Operation.LIKE,
        columnType: ColumnType.STRING,
        joinTable: null,
        operationJoin: null,
        enumClassName: null,
      };

      this.requestSearch = this.getFilters(query);
    }

    if (this.idOrder !== '') {
      const query: QuerySearch = {
        column: 'idOrder',
        value: this.idOrder,
        operation: Operation.LIKE,
        columnType: ColumnType.STRING,
        joinTable: null,
        operationJoin: null,
        enumClassName: null,
      };

      this.requestSearch = this.getFilters(query);
    }

    // city departure
    if (this.idsCityDeparture.length > 0) {
      const ids = this.idsCityDeparture.join(',');
      const query: QuerySearch = {
        column: 'idCity',
        value: ids,
        operation: Operation.JOIN,
        columnType: ColumnType.INTEGER,
        joinTable: 'cityDeparture',
        operationJoin: Operation.IN,
        enumClassName: null,
      };

      this.requestSearch = this.getFilters(query);
    }

    // city arrival
    if (this.idsCityArrival.length > 0) {
      const ids = this.idsCityArrival.join(',');
      const query: QuerySearch = {
        column: 'idCity',
        value: ids,
        operation: Operation.JOIN,
        columnType: ColumnType.INTEGER,
        joinTable: 'cityArrival',
        operationJoin: Operation.IN,
        enumClassName: null,
      };

      this.requestSearch = this.getFilters(query);
    }

    // type collect from address
    if (this.selectedTypeCollect !== '') {
      const query: QuerySearch = {
        column: 'typeCollects',
        value: this.selectedTypeCollect,
        operation: Operation.JOIN,
        columnType: ColumnType.ENUM,
        joinTable: 'typeCollect',
        operationJoin: Operation.EQUALS,
        enumClassName: 'TypeCollects',
      };

      this.requestSearch = this.getFilters(query);
    }

    // client
    if (this.clientName !== '') {
      let query: QuerySearch = {
        column: 'firstnameSender',
        value: this.clientName,
        operation: Operation.LIKE,
        columnType: ColumnType.STRING,
        joinTable: null,
        operationJoin: null,
        enumClassName: null,
      };

      this.requestSearch = this.getFilters(query);

      query = {
        column: 'lastnameSender',
        value: this.clientName,
        operation: Operation.LIKE,
        columnType: ColumnType.STRING,
        joinTable: null,
        operationJoin: null,
        enumClassName: null,
      };

      this.requestSearch = this.getFilters(query);
    }

    // idExternalClient
    if (this.clientId) {
      const query: QuerySearch = {
        column: 'idExternalClient',
        value: this.clientId,
        operation: Operation.EQUALS,
        columnType: ColumnType.INTEGER,
        joinTable: null,
        operationJoin: null,
        enumClassName: null,
      };

      this.requestSearch = this.getFilters(query);
    }

    if (this.selectedSatatus !== '') {
      this.filtersIndicator = [];
      const query: QuerySearch = {
        column: 'codesTracking',
        value: this.selectedSatatus,
        operation: Operation.JOIN,
        columnType: ColumnType.STRING,
        joinTable: 'currentStatus',
        operationJoin: Operation.EQUALS,
        enumClassName: 'null',
      };

      this.requestSearch = this.getFilters(query);
    }

    if (this.filtersIndicator.length > 0) {
      this.selectedSatatus = '';
      const filterIndicator = this.filtersIndicator.join(',');

      const query: QuerySearch = {
        column: 'codesTracking',
        value: filterIndicator,
        operation: Operation.JOIN,
        columnType: ColumnType.STRING,
        joinTable: 'currentStatus',
        operationJoin: Operation.IN,
        enumClassName: 'null',
      };

      this.requestSearch = this.getFilters(query);
    }
  }

  private getFilters(query?: QuerySearch): RequestSearch {
    if (query) {
      this.requestSearchService.addSearchQuery(query);
    }

    return this.requestSearchService.buildRequestSearch(LogicalOperation.AND);
  }

 

  private getIdicatorsPickup(): void {
    const idAgency = this.idAgency ?? '';
    this.shipementService.getIdicatorsShipment(idAgency).subscribe({
      next: (response) => (this.indicators = response),
      error: (err) => this.notification.showMessageError(err.error.message),
    });
  }

  private getAgncy(): void {
    this.agency = this.clientStorageData.getAgency();
    this.idAgency = this.agency?.idAgency;
  }

  private getExportDataShipment(): ExportShipment[] {
    const exportData = this.selection.selected.map((row) => {
      const dataObject: ExportShipment = {}; // Create an empty object to build

      if (this.isVisible('Date de création')) {
        dataObject.createdDate = this.formatMediumDatetimePipe.transform(
          row.createdDate
        );
      }

      if (this.isVisible('Référence expédition')) {
        dataObject.idShipment = row.idShipment.toFixed(0);
      }

      if (this.isVisible('Référence client')) {
        dataObject.idParcelClient = row.idParcelClient;
      }

      if (this.isVisible('Type ramassage')) {
        dataObject.pickupType = row.typeCollect.description;
      }

      if (this.isVisible('Ville depart')) {
        dataObject.cityDeparture = row.cityDeparture.cityName;
      }

      if (this.isVisible('Ville arrivee')) {
        dataObject.cityArrival = row.cityArrival.cityName;
      }

      if (this.isVisible('Taille colis')) {
        dataObject.shipmentSize = row.size;
      }

      if (this.isVisible('Id client')) {
        dataObject.idExternalClient = String(row.idExternalClient);
      }

      if (this.isVisible('Client')) {
        dataObject.sender =
          row.companySender === ''
            ? '-'
            : `${row.companySender} ${row.gsmSender}`;
      }

      if (this.isVisible('Statut')) {
        dataObject.status = row.currentStatus.description;
      }
      return dataObject; // Return the constructed object
    });

    return exportData;
  }
}
