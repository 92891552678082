import { Pagination } from "./request.model";

export function setPagination(
  page: number,
  size: number,
  sort?: string[] | null
): Pagination {
  return {
    page,
    size,
    sort,
  };
}
