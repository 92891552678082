import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { WebsocketMessage, WebsocketTypes } from '../shared/notification/models/websocketmessage';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  
  moduleSource = new Subject<any>();
  moduleS$ = this.moduleSource.asObservable();
  private messagePicupSubject: Subject<any> = new Subject<any>();
  private messageDeliverSubject: Subject<any> = new Subject<any>();
  private messageShelvingSubject: Subject<any> = new Subject<any>();

  constructor() {}
 

  sendToModule(action: WebsocketMessage): void {
    if (action.type === WebsocketTypes.NOTIFICATION_RELOAD) {
      this.messagePicupSubject.next('');
      this.messageDeliverSubject.next('');
    }
    if (action.action === 'shelving') {
      this.messageShelvingSubject.next(action);
    }
    
  }

  onMessagePickup(): Subject<any> {
    return this.messagePicupSubject;
  }
  
  onMessageDeliver(): Subject<any> {
    return this.messageDeliverSubject;
  }

  onMessageShelving(): Subject<any> {
    return this.messageShelvingSubject;
  }
}
