<div [class]="indicator.tabType!==null? 'pointer': ''" (click)="onClick()">
  <div class="card shadow-none px-1 border {{ indicator.backgroundClass }}">
    <div class="card-body p-1">
      <div class="d-flex flex-wrap align-items-center justify-content-between gap-3">
        <div>
          <span>
            <!-- title -->
            <span>
              <p class="fw-medium text-primary-light mb-1 fsz-14 fw-100">{{ indicator.title }}</p>
            </span>
            <!-- rate -->
            <span class="{{ indicator.rate | classStyle: params }}" *ngIf="indicator.rate !== null">
              <span class="mb-0 mb-0 mx-8 fsz-22 fw-bold">{{ indicator.rate }}%</span>
            </span>
          </span>

          <div class="items-icon">
            <!-- icon -->
            <div class="w-40-px h-30-px  d-flex justify-content-center align-items-center mr-16">
              <iconify-icon [icon]="indicator.icon"
                class="text-{{indicator.backgroundIcon.split('bg-')[1]}} text-2xl mb-0 mx-16">
              </iconify-icon>
            </div>

            <!-- order indicator -->
            <div *ngIf="indicator.order" >
              <span *ngIf="indicator.countOrder!== null">
                <span>
                  <span class="mb-0 mb-0 mx-10 fsz-30 fw-bold">{{ indicator.countOrder}}</span>
                  <span class="fsz-20" milestoneTranslate="order.title"> Order</span>
                </span>
                <span class="fsz-12 ml-rem-1"
                  milestoneTranslate="order.indicator-card.countShipmentInOrder"
                  [translateValues]="{countShipment:indicator.countShipment}"
                  >
                    ( for {{indicator.countShipment}} shipment)
                </span>
              </span>
  
              <span *ngIf="indicator.countOrder === null">
                <span>
                  <span class="mb-0 mb-0 mx-10 fsz-30 fw-bold"> {{ indicator.countShipment }} </span>
                  <span class="fsz-20" milestoneTranslate="shipment.title"> colis </span>
                </span>
              </span>
            </div>

            <!-- money box -->
            <div *ngIf="!indicator.order" >
              <span>
                <span>
                  <span class="mb-0 mb-0 mx-10 fsz-30 fw-bold"> {{ indicator.totalMoneyBox }} </span>
                  <span class="fsz-20" milestoneTranslate="fields.attached_mony.title"> DH </span>
                </span>
              </span>              
            </div>
          </div>

          <div *ngIf="!indicator.order" >
            <span class="fsz-12 ml-rem-1"
                  milestoneTranslate="order.indicator-card.totalMonyBox-details"
                  [translateValues]="{countShipment:indicator.countShipment,countDeliver: indicator.countDeliver }"
                  >
                  collectés et non encaissés <br>
                   (pour <strong class="mb-0 mb-0 mx-10 fsz-30 fw-bold"> {{indicator.countDeliver}} </strong> 
                   livreurs et  <strong class="mb-0 mb-0 mx-10 fsz-30 fw-bold"> {{indicator.countShipment}} </strong> colis)
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>