<div class="modal-body">
  <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
    <div
      class="alert alert-danger"
      *ngIf="createSectorError"
      data-cy="loginError"
    >
      <strong>Create Sector!</strong> {{ errorSector }}
    </div>

    <div class="row-modal">
      <div>
        <div class="form-group">
          <label class="label" for="sector">Sector name</label>
          <input
            id="sector"
            formControlName="sector"
            type="text"
            class="form-control"
            placeholder="Sector bourgone"
          />
        </div>

        <div class="form-group">
          <label class="label" for="agency">City</label>
          <milestone-dropdown-select
            [items]="cities"
            displayField="cityName"
            placeholder="Select City"
            (itemSelected)="selectCity($event)"
          >
          </milestone-dropdown-select>
        </div>
      </div>

      <!-- <milestone-draw-polygon></milestone-draw-polygon> -->
    </div>

    <!-- District Selection -->
    <div>
      <div class="form-group">
        <label for="description">
          <strong>Choisissez un quartier</strong>
        </label>
      </div>

      <mat-form-field class="qualification" appearance="outline">
        <mat-label>Quartier</mat-label>

        <!-- Multi-select dropdown -->
        <mat-select
          formControlName="districts"
          multiple
          [(ngModel)]="selectedDistrictIds"
        >
          <!-- Search input -->
          <mat-option>
            <mat-form-field style="width: 100%">
              <input
                matInput
                placeholder="Recherche..."
                (keyup)="search($event)"
                class="search"
                inputmode="text"
              />
            </mat-form-field>
          </mat-option>

          <!-- List of districts -->
          <mat-option
            *ngFor="let district of selectedDistricts"
            [value]="district.id"
          >
            {{ district.districtName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      
      <!-- Error Message -->
      <div *ngIf="userForm.get('districts')?.errors?.['required']">
        <small class="text-danger">Ce champ est obligatoire</small>
      </div>
    </div>

    <div class="form-check">
      <input
        id="confirmation"
        formControlName="confirmation"
        type="checkbox"
        class="form-check-input"
      />
      <label for="confirmation" class="form-check-label"
        >Yes, confirmation</label
      >
    </div>

    <div class="form-group mt-3">
      <button type="submit" class="btn-action-modal">
        <ng-container *ngIf="!loading; else spinner">
          <span>Create Sector</span>
        </ng-container>
        <ng-template #spinner>
          <div class="spinner-border text-light" role="status"></div>
        </ng-template>
      </button>
    </div>
  </form>
</div>
