<aside class="sidebar" [class.sidebar-open]="isVisible">
  <div class="logo-side">
    <a routerLink="/dashboard">
      <img
        src="assets/images/svg/milestone-logo.svg"
        alt="milestonelogo"
        class="sidebar-logo"
      />
    </a>
  </div>
  <div class="sidebar-menu-area">
    <ul class="sidebar-menu no-padding-left" id="sidebar-menu">
      <li *ngFor="let menu of menus">
        <a
          *ngIf="menu.type === 'simple'"
          [routerLink]="menu.routerLink"
          routerLinkActive="active"
        >
          <img [src]="menu.icon" alt="icon" class="menu-icon" />
          <span> {{ menu.title }} </span>
        </a>
        <div *ngIf="menu.type === 'dropdown'">
          <a style="cursor: pointer" (click)="menu.isOpen = !menu.isOpen">
            <img [src]="menu.icon" alt="icon" class="menu-icon" />
            <span>
              {{ menu.title }}
            </span>
            <i *ngIf="!menu.isOpen" class="material-icons"> chevron_right </i>
            <i *ngIf="menu.isOpen" class="material-icons">
              keyboard_arrow_down
            </i>
          </a>
          <ul *ngIf="menu.isOpen && menu.submenus && menu.submenus.length">
            <li *ngFor="let submenu of menu.submenus">
              <a [routerLink]="submenu.routerLink" routerLinkActive="active">
                <span> {{ submenu.title }} </span>
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <ul class="menu_logout">
      <li>
        <button (click)="logout()">
          <a class="btn_logout">
            <img src="assets/images/png/user-remove.png" /> Déconnexion
          </a>
        </button>
      </li>
    </ul>
  </div>

  <div></div>
</aside>
