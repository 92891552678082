import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { PolygonService } from '../../../shared/draw-polygon/draw-polygon.service';
import { IAgencyVM } from '../../agency/models/agency-vm.model';
import { CityVM, ICityVM } from '../../city/models/city-vm.model';
import { IZoneVM } from '../../zone/models/zone-vm.model';
import { CreateSectorVM } from '../models/create-serctor-vm.model';
import { CitiesService } from '../../city/services/cities.service';
import { SectorsService } from '../services/sectors.service';
import { IDistrictsVM } from '../../districts/models/district-vm.model';
import { DistrictsService } from '../../districts/services/districts.service';

@Component({
  selector: 'milestone-create-sector-from',
  templateUrl: './create-sector-from.component.html',
  styleUrls: ['./create-sector-from.component.scss'],
})
export class CreateSectorFromComponent implements OnInit {
  userForm: FormGroup;
  loading = false;

  districts: IDistrictsVM[] = [];
  cities: ICityVM[] = [];
  selectedDistricts: IDistrictsVM[] = []; // Filtered districts
  selectedDistrictIds: number[] = []; // Selected district IDs

  @Output() formSubmit = new EventEmitter<any>();

  createSectorError = false;
  errorSector = '';

  constructor(
    private fb: FormBuilder,
    private notification: NotificationService,
    private polygonService: PolygonService,
    private citiesService: CitiesService,
    private sectorsService: SectorsService,
    private districtsService: DistrictsService
  ) {
    this.userForm = this.fb.group({
      sector: ['', Validators.required],
      city: ['', Validators.required],
      districts: [[], Validators.required], // Expecting an array of IDs
      confirmation: [false, Validators.required],
    });
  }

  ngOnInit(): void {
    this.loadCities();

    this.polygonService.polygon$.subscribe((points) => {
      this.userForm.patchValue({ geom: points });
    });
  }

  loadCities(): void {
    this.citiesService.getCities(0, 263).subscribe(
      (response) => {
        // Handle response
        this.cities = response.content;
      },
      () => {
        this.notification.showMessageError('Error loading cities');
      }
    );
  }

  loadDistrictsByCity(idCity: number): void {
    this.districtsService.districtByCity(idCity).subscribe(
      (response) => {
        this.districts = response;
        this.selectedDistricts = [...response];
      },
      () => {
        this.notification.showMessageError('Error loading districts');
      }
    );
  }

  search(event: Event): void {
    const input = event.target as HTMLInputElement | null;
    if (input?.value) {
      const value = input.value.toLowerCase().trim();
      this.selectedDistricts = this.districts.filter((district) =>
        district.districtName.toLowerCase().includes(value)
      );
    } else {
      this.selectedDistricts = [...this.districts];
    }
  }

  onSubmit(): void {
    if (this.userForm.valid && this.userForm.value.confirmation) {
      this.loading = true;
      const newSector = new CreateSectorVM(
        this.userForm.value.sector,
        this.userForm.value.city,
        // this.userForm.value.districts
        this.userForm.value.districts.map((id: number) =>
          this.districts.find((district) => district.id === id)
        )
      );

      this.sectorsService.createSector(newSector).subscribe(
        () => {
          this.notification.showMessageSuccess('Sector created successfully');
          this.createSectorError = false;
          this.loading = false;
          this.formSubmit.emit(this.userForm.value);
        },
        (error) => {
          this.notification.showMessageError('Error creating sector');
          this.errorSector = error.error.message;
          this.createSectorError = true;
          this.loading = false;
        }
      );
    } else {
      this.notification.showMessageError('Please fill in all required fields');
    }
  }

  selectCity(event: CityVM): void {
    this.userForm.patchValue({ city: event.idCity });
    this.loadDistrictsByCity(event.idCity);
  }
}
