import { Injectable } from '@angular/core';

import * as XLSX from 'xlsx';
@Injectable({
  providedIn: 'root',
})
export class ExportService {

  
  // Export xlsx
  exportDataXlsx(exportData: any, title: String) {
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'SelectedRows');
    XLSX.writeFile(workbook, `${title}.xlsx`);
  }
}
