// /api
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApplicationConfigService } from 'src/app/core/config/application-config.service';
import { AppConstant } from 'src/app/shared/app-constant';
import { ClientDataStorageService } from 'src/app/shared/clientDataStorage/client-data-storage.service';
import { CreateSectorVM } from '../models/create-serctor-vm.model';
import { ISectorVM, SectorPagenationsVM ,SectorVM} from '../models/sector-vm.model';


@Injectable({
  providedIn: 'root',
})
export class SectorsService {
  constructor(
    private http: HttpClient,
    private storageService: ClientDataStorageService,
    private applicationConfigService: ApplicationConfigService
  ) {}

  createSector(sector: CreateSectorVM): Observable<void> {
    const data = {
      sector: sector.sector,
      city: { idCity: sector.city },
      districts: sector.districts,
    };
    return this.http
      .post<ISectorVM>(
        this.applicationConfigService.getEndpointFor('/managers/sectors'),
        data,
        AppConstant.httpOptions
      )
      .pipe(map((response) => console.log('response', response)));
  }

  getSectors(page: Number, size: Number): Observable<SectorPagenationsVM> {
    return this.http
      .get<SectorPagenationsVM>(
        this.applicationConfigService.getEndpointFor(
          '/sectors/query?page=' + page + '&size=' + size
        ),
        AppConstant.httpOptions
      )
      .pipe(map((response) => response));
  }

  getAllSectors(): Observable<SectorVM[]> {
    return this.http
      .get<SectorVM[]>(
        this.applicationConfigService.getEndpointFor(
          '/sectors/all'
        ),
        AppConstant.httpOptions
      )
      .pipe(map((response) => response));
  }
}
