import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { ISectorVM, SectorVM } from './models/sector-vm.model';
import { SectorsService } from './services/sectors.service';

@Component({
  selector: 'milestone-sectors',
  templateUrl: './sectors.component.html',
  styleUrls: ['./sectors.component.scss'],
})
export class SectorsComponent implements OnInit {
  pageInit = 10;
  isModalOpen = false;
  totalPages = 0;
  totalElements = 0;
  currentPage = 0;

  columns = [
    { header: 'ID', field: 'id' },
    { header: 'Sector', field: 'sector' },
    // { header: 'geom', field: 'geom' },
    { header: 'City', field: 'city' },
    { header: 'Zone', field: 'zone' },
    { header: 'Agency', field: 'agency' },
    // { header: 'Created by', field: 'createdBy' },
    { header: 'Created date', field: 'createdDate' },
    { header: 'Last modified by', field: 'lastModifiedBy' },
    { header: 'Last modified date', field: 'lastModifiedDate' },
  ];

  sectors: SectorVM[] = [];
  dropdownFilters = [];

  constructor(
    private sectorsService: SectorsService,
    private notification: NotificationService
  ) {}

  ngOnInit(): void {
    this.loadSectors();
  }

  loadSectors(): void {
    this.sectorsService.getSectors(this.currentPage, this.pageInit).subscribe(
      (response) => {
        this.sectors = this.transformResponse(response.content);
        this.totalPages = response.totalPages;
        this.totalElements = response.totalElements;
        this.currentPage = response.number;
      },
      () => {
        this.notification.showMessageError('Error loading sectors');
      }
    );
  }

  handleFormSubmit(formData: any): void {
    console.log('Form Submitted', formData);
    this.isModalOpen = false;
  }

  handleClose(): void {
    this.isModalOpen = false;
  }

  onClickCreate(): void {
    console.log('Create button clicked');
    this.isModalOpen = true;
  }

  onExportClick(): void {
    console.log('Export button clicked');
  }

  handleRowAction(rowData: any): void {
    console.log('Action Clicked with Row Data:', rowData);
    // Handle the row data, e.g., route to another page or perform some action
  }

  private transformResponse(response: any[]): ISectorVM[] {
    return response.map((item) => ({
      id: item.id,
      sector: item.sector,
      geom: item.geom,
      city: item.city ? item.city.cityName : null,
      zone: item.city ? item.city.zone.zone : null,
      agency: item.city ? item.city.agency.agencyName : null,
      createdBy: item.createdBy,
      createdDate: item.createdDate,
      lastModifiedBy: item.lastModifiedBy,
      lastModifiedDate: item.lastModifiedDate,
    }));
  }

  onNextPage(event: any): void {
    console.log('Next Page Clicked');
    console.log('Event:', event);
    this.currentPage = event['pageIndex'];
    this.pageInit = event['pageSize'];
    console.log('Current Page:', this.currentPage);
    this.loadSectors();
  }
}
