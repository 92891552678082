import { Component } from '@angular/core';

@Component({
  selector: 'milestone-smart-locker',
  templateUrl: './smart-locker.component.html',
  styleUrls: ['./smart-locker.component.scss']
})
export class SmartLockerComponent {
  
}
