import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IParamsVM } from '../../modules/params/models/params-vm.model';
import { IndicatorOrderVM } from './indicator-order-card.model';

@Component({
  selector: 'milestone-indicator-order-card',
  templateUrl: './indicator-order-card.component.html',
  styleUrls: ['./indicator-order-card.component.scss'],
})
export class IndicatorOrderCardComponent {
  
  @Input() indicator!: IndicatorOrderVM;
  @Input() params?: IParamsVM | null;

  // Create an EventEmitter to emit the clicked title to the parent
  @Output() indicatorClicked: EventEmitter<string> = new EventEmitter<string>();

  // Method to handle click and emit the title to the parent
  onClick(): void {
    if(this.indicator.tabType!==null){
      this.indicatorClicked.emit(this.indicator.tabType);
    }
    
  }

}
