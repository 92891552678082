<div class="modal-overlay" (click)="close()"></div>
<div class="modal-container">
  <div class="modal-header">
    <div class="header-left">
      <ng-container *ngIf="icon">
        <img class="icon" src="{{ icon }}" />
      </ng-container>
      <div class="text">{{ title }}</div>
    </div>
    <div class="header-right">
      <button mat-icon-button (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div>
    <ng-content></ng-content>
  </div>
</div>
