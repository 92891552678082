import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApplicationConfigService } from 'src/app/core/config/application-config.service';
import { AppConstant } from 'src/app/shared/app-constant';
import { ClientDataStorageService } from 'src/app/shared/clientDataStorage/client-data-storage.service';
import { CreateDistrictsVM } from '../models/create-district-vm.model';
import {
  DistrictsPagenationsVM,
  DistrictsVM,
  IDistrictsVM,
} from '../models/district-vm.model';

@Injectable({
  providedIn: 'root',
})
export class DistrictsService {
  constructor(
    private http: HttpClient,
    private storageService: ClientDataStorageService,
    private applicationConfigService: ApplicationConfigService
  ) {}

  createCity(city: CreateDistrictsVM): Observable<void> {
    const data = {
      idCity: city.idCity,
      cityName: city.cityName,
      agency: { idAgency: city.agency },
      zone: { zone: city.zone },
    };
    return this.http.post<void>(
      this.applicationConfigService.getEndpointFor('/admin/districts'),
      data,
      AppConstant.httpOptions
    );
  }

  getDistricts(page: Number, size: Number): Observable<DistrictsPagenationsVM> {
    return this.http
      .get<DistrictsPagenationsVM>(
        this.applicationConfigService.getEndpointFor(
          '/districts/query?page=' + page + '&size=' + size
        ),
        AppConstant.httpOptions
      )
      .pipe(map((response) => response));
  }

  getAllDistricts(): Observable<DistrictsVM[]> {
    return this.http
      .get<DistrictsVM[]>(
        this.applicationConfigService.getEndpointFor('/Districts/all'),
        AppConstant.httpOptions
      )
      .pipe(map((response) => response));
  }

  districtByCity(idCity: number): Observable<DistrictsVM[]> {
    return this.http.get<DistrictsVM[]>(
      this.applicationConfigService.getEndpointFor(
        `/districts/by-city/${idCity}`
      ),
      AppConstant.httpOptions
    );
  }
}
