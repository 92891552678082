import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './table.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/app/angular-material/angular-material.module';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  declarations: [TableComponent],
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    NgbModule,
    AngularMaterialModule,
  ],
  exports: [TableComponent],
})
export class TableModule {}
