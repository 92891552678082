import { Injectable } from '@angular/core';
import { ISidebar } from './sidebar.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  // Order public members before private members

  // Private members
  sidebarVisible = new BehaviorSubject<boolean>(true);

  // Public members
  sidebarVisible$ = this.sidebarVisible.asObservable();

  iconRelativepath = 'assets/images/svg/sideBare/';

  menus: ISidebar[] = [
    {
      title: 'Tableau de bord',
      icon: this.iconRelativepath + 'table-board.svg',
      type: 'simple',
      routerLink: 'dashboard',
      authorities: '',
    },
    {
      title: 'Ramassage',
      icon: this.iconRelativepath + 'booking.svg',
      routerLink: '/pickup-operations',
      type: 'simple',
      authorities: '',
    },
    {
      title: 'Transport',
      icon: this.iconRelativepath + 'transport.svg',
      type: 'dropdown',
      authorities: '',
      submenus: [
        {
          title: 'En départ',
          icon: this.iconRelativepath + 'transport.svg',
          type: 'simple',
          routerLink: '/transport/on-departure',
          authorities: '',
        },
        {
          title: 'En arrivage',
          icon: this.iconRelativepath + 'transport.svg',
          type: 'simple',
          routerLink: '/transport/on-arrival',
          authorities: '',
        },
      ],
    },
    {
      title: 'Livraison',
      icon: this.iconRelativepath + 'booking.svg',
      routerLink: '/order/order-delivery',
      type: 'simple',
      authorities: '',
    },
    {
      title: 'Feuille de chargement',
      icon: this.iconRelativepath + 'carrier-file.svg',
      type: 'simple',
      routerLink: '/carrier-file',
      authorities: '',
    },
    {
      title: 'Gestion des colis',
      icon: this.iconRelativepath + 'open-box.svg',
      routerLink: '/shipments',
      type: 'simple',
      authorities: '',
    },
    {
      title: 'Suivi des sacs',
      icon: this.iconRelativepath + 'bag.svg',
      routerLink: '/transport/all-sac',
      type: 'simple',
      authorities: '',
    },
    {
      title: 'Suivi des opérateurs',
      icon: this.iconRelativepath + 'connected-users.svg',
      type: 'simple',
      routerLink: '/operators',
      authorities: '',
    },
    {
      title: 'gestion des caisses',
      icon: this.iconRelativepath + 'money-bank.svg',
      type: 'simple',
      routerLink: '/moneyBox',
      authorities: '',
    },

    {
      title: 'Administration',
      icon: this.iconRelativepath + 'admin.svg',
      type: 'dropdown',
      authorities: ['ROLE_ADMIN'],
      submenus: [
        {
          title: 'Users',
          icon: this.iconRelativepath + 'booking.svg',
          type: 'simple',
          routerLink: '/users',
          authorities: '',
        },
        {
          title: 'Zones',
          icon: this.iconRelativepath + 'booking.svg',
          type: 'simple',
          routerLink: '/zones',
          authorities: '',
        },
        {
          title: 'Agencies',
          icon: this.iconRelativepath + 'booking.svg',
          type: 'simple',
          routerLink: '/agencies',
          authorities: '',
        },
        {
          title: 'Cities',
          icon: this.iconRelativepath + 'booking.svg',
          type: 'simple',
          routerLink: '/cities',
          authorities: '',
        },
        {
          title: 'Sectors',
          icon: this.iconRelativepath + 'booking.svg',
          type: 'simple',
          routerLink: '/sectors',
          authorities: '',
        },
        {
          title: 'Sac',
          icon: this.iconRelativepath + 'booking.svg',
          type: 'simple',
          routerLink: '/sacs',
          authorities: '',
        },
        {
          title: 'Bac',
          icon: this.iconRelativepath + 'booking.svg',
          type: 'simple',
          routerLink: '/bacs',
          authorities: '',
        },
        {
          title: 'Permission Modules',
          icon: this.iconRelativepath + 'booking.svg',
          type: 'simple',
          routerLink: '/modules',
          authorities: '',
        },
        {
          title: 'Permission Functions',
          icon: this.iconRelativepath + 'booking.svg',
          type: 'simple',
          routerLink: '/functions',
          authorities: '',
        },
        {
          title: 'User Profile',
          icon: this.iconRelativepath + 'booking.svg',
          type: 'simple',
          routerLink: '/profiles',
          authorities: '',
        },
        {
          title: 'User types',
          icon: this.iconRelativepath + 'booking.svg',
          type: 'simple',
          routerLink: '/user-types',
          authorities: '',
        },
      ],
    },
  ];

  constructor() {}

  toggleSidebar(): void {
    this.sidebarVisible.next(!this.sidebarVisible.value);
  }

  toggle(): any {
    // this.toggled = !this.toggled;
  }

  getSidebarState(): any {
    // return this.toggled;
  }

  isSidebarVisible(): boolean {
    return this.sidebarVisible.value;
  }

  getMenuList(): any {
    return this.menus;
  }
}
