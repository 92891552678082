import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { AgencyVM } from './models/agency-vm.model';
import { AgenciesService } from './services/agencies.service';

@Component({
  selector: 'milestone-agencies',
  templateUrl: './agencies.component.html',
  styleUrls: ['./agencies.component.scss'],
})
export class AgenciesComponent implements OnInit {
  pageInit = 10;
  isModalOpen = false;
  totalPages = 0;
  totalElements = 0;
  currentPage = 0;

  columns = [
    { header: 'ID', field: 'id' },
    { header: 'idAgency', field: 'idAgency' },
    { header: 'Agence', field: 'agencyName' },
    { header: 'Description', field: 'description' },
    { header: 'Address Agency', field: 'addressAgency' },
    { header: 'position', field: 'position' },
    { header: 'Created by', field: 'createdBy' },
  ];

  agencies: AgencyVM[] = [];

  dropdownFilters = [];

  constructor(
    private agenciesService: AgenciesService,
    private router: Router,
    private notification: NotificationService
  ) {}

  ngOnInit(): void {
    this.loadAgencies();
  }

  loadAgencies(): void {
    this.agenciesService.query(this.currentPage, this.pageInit).subscribe({
      next: (response) => {
        this.agencies = response.content;
        this.totalPages = response.totalPages;
        this.totalElements = response.totalElements;
        this.currentPage = response.number;
      },
      error: (err) =>
        this.notification.showMessageError(
          `Error loading Agencies ${String(err.error.message)}`
        ),
    });
  }

  handleFormSubmit(formData: any): void {
    this.isModalOpen = false;
  }

  handleClose(): void {
    this.isModalOpen = false;
  }

  onClickCreate(): void {
    this.isModalOpen = true;
  }

  onExportClick(): void {
    console.log('Export button clicked');
  }

  handleRowAction(rowData: any): void {
    console.log('Action Clicked with Row Data:', rowData);
    // Handle the row data, e.g., route to another page or perform some action
  }

  onNextPage(event: any): void {
    console.log('Next Page Clicked');
    console.log('Event:', event);
    this.currentPage = event['pageIndex'];
    this.pageInit = event['pageSize'];
    console.log('Current Page:', this.currentPage);
    this.loadAgencies();
  }
}
