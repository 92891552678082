import { Pagination } from '../../core/request/request.model';

 export function formatDateToYYYYMMDD(date: Date): string {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const dd = String(date.getDate()).padStart(2, '0');

    return `${yyyy}-${mm}-${dd}`;
  }

export function setPagination(page: number,size: number,sort?: string[] | null): Pagination {
  return {
    page,
    size,
    sort,
  };
}
