<!--indicator-->
<div class="col-md-12 row-indicator" style="margin: 0px auto">
  <milestone-indicator-shipment-card
    class="indicator_pickup"
    *ngFor="let indicator of indicators"
    [indicator]="indicator"
    (indicatorClicked)="redirect($event)"
  ></milestone-indicator-shipment-card>
</div>

<div class="mt-2">
  <!-- filters -->

  <button class="btn_filtre" (click)="showHideBelowFiltre();">
    Filtre
    <mat-icon *ngIf="showFiltre">expand_less</mat-icon>
    <mat-icon *ngIf="!showFiltre">expand_more</mat-icon> 
  </button>
  <div class="filter_shipment" *ngIf="showFiltre">
    <div class="date-range-container">
      <input type="text" id="daterange" class="form-control" />
    </div>

    <div>
      <div class="search-box">
        <input
          type="number"
          class="form-control"
          id="refShipment"
          placeholder="Réf. expédition"
          [(ngModel)]="idShipement"
          (keyup)="onFiltersChange()"
        />
      </div>
    </div>

    <div>
      <div class="search-box">
        <input
          type="text"
          class="form-control"
          id="refClient"
          placeholder="Réf. code client"
          [(ngModel)]="idParcelClient"
          (keyup)="onFiltersChange()"
        />
      </div>
    </div>

    <div>
      <div class="search-box">
        <input
          type="text"
          class="form-control"
          id="refOrder"
          placeholder="Réf. order"
          [(ngModel)]="idOrder"
          (keyup)="onFiltersChange()"
        />
      </div>
    </div>

    <!-- city -->
    <div>
      <mat-form-field>
        <mat-label>Ville de départ</mat-label>
        <mat-select
          [(ngModel)]="idsCityDeparture"
          (ngModelChange)="onFiltersChange()"
          multiple
        >
          <mat-option *ngFor="let city of cities" [value]="city.idCity">
            {{ city.cityName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <div>
      <mat-form-field>
        <mat-label>Ville d'arrivé </mat-label>
        <mat-select
          [(ngModel)]="idsCityArrival"
          (ngModelChange)="onFiltersChange()"
          multiple
        >
          <mat-option *ngFor="let city of cities" [value]="city.idCity">
            {{ city.cityName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field>
        <mat-label> Type de ramassage </mat-label>
        <mat-select
          [(ngModel)]="selectedTypeCollect"
          (ngModelChange)="onFiltersChange()"
        >
          <mat-option
            *ngFor="let typeCollect of typesCollects"
            [value]="typeCollect.typeCollects"
          >
            {{ typeCollect.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- client name -->
    <div>
      <div class="search-box">
        <input
          type="text"
          class="form-control"
          id="client"
          placeholder="Client"
          [(ngModel)]="clientName"
          (keyup)="onFiltersChange()"
        />
      </div>
    </div>

    <!-- client id -->
    <div>
      <div class="search-box">
        <input
          type="number"
          class="form-control"
          id="idExternalClient"
          placeholder="Id client"
          [(ngModel)]="clientId"
          (keyup)="onFiltersChange()"
        />
      </div>
    </div>

    <!-- Statut -->
    <div>
      <select
        class="form-select"
        id="statut"
        [(ngModel)]="selectedSatatus"
        (change)="onFiltersChange()"
        [ngModelOptions]="{ standalone: true }"
      >
        <option selected value>Statut</option>
        <option
          *ngFor="let statut of statutShipment"
          [value]="statut.codesTracking"
        >
          {{ statut.description }}
        </option>
      </select>
    </div>
  </div>

  <div class="example-container" #TABLE>
    <table mat-table #table [dataSource]="dataSource" class="table" matSort (matSortChange)="onSort($event)" [matSortDisableClear]="true">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef style="width: 3%">
          <mat-checkbox
            class="example-margin"
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <!-- ID Column -->

      <ng-container
        matColumnDef="Date de création"
        *ngIf="isVisible('Date de création')"
       
        >
        <th mat-header-cell *matHeaderCellDef  mat-sort-header="createdDate">Date de création</th>
        <td mat-cell *matCellDef="let element">
          <div class="row">
            <div
              *ngIf="element.currentStatus.codesTracking?.includes('ISSUE') || element.currentStatus.codesTracking?.includes('REFUSED')"
              class="col-sm-6"
              >
              <i class="material-icons" style="font-size: 19px; color: red">
                warning
              </i>
            </div>

            <div *ngIf="element.askReturn" class="col-sm-6">
              <img src="assets/images/svg/return.svg" alt="return" width="20">
            </div>
            <div class="col-sm-12">
              {{ element.createdDate | formatMediumDatetime }}
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container
        matColumnDef="Référence ordre"
        *ngIf="isVisible('Référence ordre')"
      >
        <th mat-header-cell *matHeaderCellDef>Réf. ordre</th>
        <td mat-cell *matCellDef="let element">
          <a [routerLink]="['/order/details', element.idOrder]">
            {{element.idOrder}}
          </a>
        </td>
      </ng-container>

      <ng-container
        matColumnDef="Référence expédition"
        *ngIf="isVisible('Référence expédition')"
      >
        <th mat-header-cell *matHeaderCellDef>Réf. expédition</th>
        <td mat-cell *matCellDef="let element">
          <a [routerLink]="['/shipments/details', element.idShipment]"> 
            {{element.idShipment}} 
          </a>
        </td>
      </ng-container>

      <ng-container
        matColumnDef="Référence client"
        *ngIf="isVisible('Référence client')"
      >
        <th mat-header-cell *matHeaderCellDef>Réf. code client</th>
        <td mat-cell *matCellDef="let element">
          <a [routerLink]="['/shipments/details', element.idShipment]"> 
            {{ element.idParcelClient }} 
          </a>
        </td>
      </ng-container>

      <ng-container matColumnDef="Id client" *ngIf="isVisible('Id client')">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="idExternalClient">Id client</th>
        <td mat-cell *matCellDef="let element">
          {{ element.idExternalClient }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="Type ramassage"
        *ngIf="isVisible('Type ramassage')"
      >
        <th mat-header-cell *matHeaderCellDef  style="width: 10%;">Type ramassage</th>
        <td mat-cell *matCellDef="let element">
          <!--{{ element.typeCollect.description }}-->
          <span
            *ngIf="
              element.typeCollect.description === 'ramassage AIO';
              else consigne
            "
          >
            <div class="bloc_typeramassage">
              <div>
                <img src="assets/images/png/home-icon.png" alt="home icon" />
              </div>
              <div style="padding-left: 10px; padding-top: 10px">
                <p>En Adresse</p>
              </div>
            </div>
          </span>
          <ng-template #consigne>
            <div class="bloc_typeramassage">
              <div style="padding-left: 10px">
                <img src="assets/images/svg/smartlocker.svg" alt />
              </div>
              <div style="padding-left: 10px; padding-top: 10px">
                <p>En consigne</p>
              </div>
            </div>
          </ng-template>
        </td>
      </ng-container>

      <ng-container
        matColumnDef="Ville depart"
        *ngIf="isVisible('Ville depart')"
      >
        <th mat-header-cell *matHeaderCellDef>Ville de départ</th>
        <td mat-cell *matCellDef="let element">
          {{ element.cityDeparture.cityName }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="Ville arrivee"
        *ngIf="isVisible('Ville arrivee')"
      >
        <th mat-header-cell *matHeaderCellDef>Ville d'arrivé</th>
        <td mat-cell *matCellDef="let element">
          {{ element.cityArrival.cityName }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="Taille colis"
        *ngIf="isVisible('Taille colis')"
      >
        <th mat-header-cell *matHeaderCellDef>Taille colis</th>
        <td mat-cell *matCellDef="let element">
          {{ element.size }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Client" *ngIf="isVisible('Client')">
        <th mat-header-cell *matHeaderCellDef>Client</th>
        <td mat-cell *matCellDef="let element">
       
          {{element.companySender !== "" ? element.companySender : '-'}}
          <br>
          {{element.firstnameSender + " " + element.lastnameSender}}
         
          {{ element.gsmSender.replace("00212", "0") }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Statut" *ngIf="isVisible('Statut')">
        <th mat-header-cell *matHeaderCellDef style="width: 15%">Statut</th>
        <td mat-cell *matCellDef="let element">
          <span
            class="{{
              element.currentStatus.codesTracking.includes('ISSUE') ||
              element.currentStatus.codesTracking.includes('REFUSED')
                ? 'incident'
                : element.currentStatus.codesTracking.includes(
                    'PICKUP_INFO_PICKED_UP'
                  )
                ? 'closed'
                : element.currentStatus.codesTracking.includes('CANCELED')
                ? 'canceled'
                : element.currentStatus.codesTracking.includes(
                    'PICKUP_INFO_RESCHEDULED'
                  )
                ? 'resheduled'
                : element.currentStatus.codesTracking.includes(
                    'PICKUP_INFO_ASSIGNED'
                  )
                ? 'assigned'
                : ''
            }}"
          >
            {{ element.currentStatus.description }}
          </span>
          <br />
          <span
            *ngIf="element.currentStatus.codesTracking.includes('RESCHEDULED')"
            class="text-orange poppins-bold"
          >
            {{ element.dateReschedule | formatMediumDatetime }}
          </span>
        </td>
      </ng-container>

     <!-- date de modification -->
     <ng-container matColumnDef="date de modification" *ngIf="isVisible('date de modification')">
      <th mat-header-cell *matHeaderCellDef>Date de modification</th>
      <td mat-cell *matCellDef="let element">
       <span>
          {{ element.lastModifiedDate | formatMediumDatetime}}
        </span></td>
    </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>
          <button (click)="toggleColumns()">
            <iconify-icon icon="ant-design:setting-filled"></iconify-icon>
          </button>

          <div *ngIf="showColumns" class="togglecolumn">
            <mat-selection-list [(ngModel)]="visibleColumns">
              <mat-list-option
                *ngFor="let column of allColumns; let i = index"
                [value]="column"
                style="--mdc-checkbox-selected-icon-color: #1e3a8a"
              >
                {{ column }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </th>

        <td mat-cell *matCellDef="let element"></td>
      </ng-container>

      <!-- Name Column -->

      <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: visibleColumns"></tr>
    </table>
    <span *ngIf="totalItems === 0" style="display: table; margin: 10px auto">
      Aucune données trouvées
    </span>
  </div>
  <mat-paginator
    *ngIf="totalItems !== 0"
    [length]="totalItems"
    [pageSize]="pageSize"
    [pageSizeOptions]="[1, 2, 5, 10, 20]"
    [showFirstLastButtons]="true"
    aria-label="{{ 'itemsParPage' }}"
    (page)="onTableDataChange($event)"
  ></mat-paginator>
  <div></div>
  <button *ngIf="totalItems !== 0" (click)="ExportToExcel()">
    <img src="assets/images/svg/excel.svg" alt="export" width="40px" /></button
  >&nbsp;
  <button *ngIf="totalItems !== 0" (click)="exportToPDF()">
    <img src="assets/images/svg/pdf.svg" alt="export" width="40px" />
  </button>
</div>
