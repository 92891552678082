export class CreateDistrictsVM {
  constructor(
    public idCity?: string | null,
    public cityName?: string | null,
    public agency?: string | null,
    public zone?: string | null,
    public lat?: string | null,
    public lng?: string | null,
    public radiusInMetres?: number | null,
  ) {}
}





