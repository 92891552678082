import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace002With0',
})
export class Replace002With0Pipe implements PipeTransform {
  transform(value: string | undefined): string {
    if (!value){ return ''; } // Handle undefined or empty input
    return value.replace(/00212/g, '0');
  }
}
